<template>
  <el-dialog
      v-model="isShow"
      title="设备详情"
      width="800px"
      :before-close="handleClose"
  >
    <template #default>
      <el-table :data="data" row-key="id" border height="350" v-loading="loading" fit>
        <el-table-column prop="deviceLocation.deviceName" label="设备名称" width="124px" fixed="left"/>
        <el-table-column prop="statusName" label="安装状态" width="124px"/>
        <el-table-column label="安装位置" width="124px">
          <template #default="scope">
            <dict-tag :options="dict.type.device_location_installation_location"
                      :value="scope.row.deviceLocation.installationLocation"/>
          </template>
        </el-table-column>
        <el-table-column label="安装方式" width="124px">
          <template #default="scope">
            <dict-tag :options="dict.type.device_location_installation_method"
                      :value="scope.row.deviceLocation.installationMethod"/>
          </template>
        </el-table-column>
        <el-table-column prop="deviceLocation.pileCount" label="小桩数量" width="124px">
          <template #default="scope">
            {{ scope.row.deviceLocation.installationMethod === selectDictValue(dict.type.device_location_installation_method, 'ColumnInstallation') ? scope.row.deviceLocation.pileCount : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="插座数量" width="124px">
          <template #default="scope">
            {{ scope.row.deviceLocation.installationMethod === selectDictValue(dict.type.device_location_installation_method, 'WallMount') ? scope.row.deviceLocation.socketCount : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="整体安装位置图" width="124px" >
          <template #default="scope">
            <el-image v-for="file in scope.row.deviceLocation.installationPositionFileList"
                      style="width: 50px; height: 50px;margin:0 10px"
                      preview-teleported="true"
                      :preview-src-list="[requestUrl+file.storageBlob.newFilename]"
                      :src="requestUrl+file.storageBlob.newFilename" fit="contain"/>
          </template>
        </el-table-column>
        <el-table-column label="点位图" fit="true">
          <template #default="scope">
            <el-image v-for="file in scope.row.deviceLocation.bitmapFileList" style="width: 50px; height: 50px;margin:0 10px"
            preview-teleported="true"
                      :preview-src-list="[requestUrl+file.storageBlob.newFilename]"
                      :src="requestUrl+file.storageBlob.newFilename" fit="contain"/>
          </template>
        </el-table-column>
        <el-table-column label="取电位置" width="124px">
          <template #default="scope">
            {{ scope.row.deviceLocation.powerLocation }}
            <el-image v-for="file in scope.row.deviceLocation.powerLocationFileList" style="width: 50px; height: 50px;margin:0 10px"
                      :preview-src-list="[requestUrl+file.storageBlob.newFilename]"
                      :src="requestUrl+file.storageBlob.newFilename" fit="contain"
                      preview-teleported="true"/>
          </template>
        </el-table-column>
        <el-table-column label="线材预算" width="124px">
          <template #default="scope">
            4mm²线-{{ scope.row.deviceLocation.wireBudget4 }}米；<br/> 1.5mm²线-{{ scope.row.deviceLocation.wireBudget15 }}米
          </template>
        </el-table-column>
        <el-table-column prop="deviceLocation.fee" label="安装费用(元)" width="124px">
        </el-table-column>
        <el-table-column prop="deviceLocation.description" label="安装说明" width="124px">
        </el-table-column>
        <el-table-column label="操作" width="124px" fixed="right">
          <template #default="scope">
            <el-tooltip content="任务历史">
              <el-icon :size="18" style="margin-right:10px" @click="showTaskHistory(scope.row)">
                <Clock color="#409EFF"/>
              </el-icon>
            </el-tooltip>

            <el-tooltip content="安装结果" v-if="scope.row.statusName !=='等待安装'">
              <el-icon :size="18" style="margin-right:10px" @click="showInstallResult(scope.row)">
                <Setting color="#409EFF"/>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="验收结果"
                        v-if="scope.row.statusCode =='fail' &&  scope.row.statusCode =='completed'">
              <el-icon :size="20" style="margin-right:10px" @click="showCheckResult(scope.row)">
                <Checked color="#409EFF"/>
              </el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-model="innerVisible"
          width="500px"
          title="操作历史"
          append-to-body
      >
        <operation-history-list :reference-id="installationTaskId"
                                reference-type="InstallationTask"></operation-history-list>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleCloseInner">确定</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog
          v-model="innerInstallResultVisible"
          width="500px"
          title="安装结果"
          append-to-body
      >
        <operation-history-list :reference-id="installationTaskId"
                                reference-type="InstallationTask"></operation-history-list>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="handleCloseInstallResultInner">确定</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <installation-result
      v-if="isShowInstallationResultModal"
      v-model:visible="isShowInstallationResultModal"
      :id="showResultId"
  />
  <accept-result
      v-if="isShowAcceptResultModal"
      v-model:visible="isShowAcceptResultModal"
      :id="showResultId"
  />

</template>

<script>
import {isResOK} from "@/api/response";
import {listInstallationTask} from "@/api/installation_task";
import DictTag from "@/components/DictTag/index.vue";
import {selectDictValue} from "../../utils/ruoyi";
import {Checked, Clock, Setting} from "@element-plus/icons-vue";
import OperationHistoryList from "@/view/operation_history/index.vue";
import InstallationResult from '@/view/installation_task/InstallationResult.vue'
import AcceptResult from '@/view/installation_task/AcceptResult.vue'

export default {
  name: "InstallationTaskIndex",
  components: {OperationHistoryList, Checked, Setting, Clock, DictTag, InstallationResult, AcceptResult},
  dicts: ['device_location_installation_location', 'device_location_installation_method'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    installationPlanId: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:visible', 'completed'],
  data() {
    return {
      data: [],
      loading: false,
      openDetail: false,
      innerVisible: false,
      requestUrl: process.env.VUE_APP_BASE_API,
      installationTaskId: 0,
      innerInstallResultVisible: false,
      isShowInstallationResultModal: false, // 安装结果
      isShowAcceptResultModal: false, // 验收结果
      showResultId: 0,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    selectDictValue,
    showTaskHistory(row) {
      this.installationTaskId = row.id
      this.innerVisible = true
    },
    getList() {
      this.loading = true
      listInstallationTask({installationPlanId: this.installationPlanId}).then(res => {
        if (isResOK(res)) {
          this.data = res.rows
        }
        this.loading = false
      })
    },
    showInstallResult(row) {
      this.showResultId = row.id
      this.isShowInstallationResultModal = true
    },
    showCheckResult(row) {
      this.showResultId = row.id
      this.isShowAcceptResultModal = true
    },
    handleClose() {
      if (this.changed) {
        this.$emit('completed')
      }
      this.isShow = false
    },
    handleCloseInner() {
      this.innerVisible = false
    },
    handleCloseInstallResultInner() {
      this.innerInstallResultVisible = false
    },
  },
}
</script>

<style scoped>
</style>
