<template>
  <el-dialog
      v-model="isShow"
      title="验收结果"
      width="500px"
      :before-close="handleClose"
  >
    <el-descriptions column="1">
      <el-descriptions-item label="验收人："></el-descriptions-item>
      <el-descriptions-item label="验收时间">{{ acceptanceResult.createTime }}</el-descriptions-item>
      <el-descriptions-item label="验收结果">{{ acceptanceResult.installationTask?.statusName }}</el-descriptions-item>
      <el-divider />
      <el-descriptions-item label="外观是否完整：">
        <div>
          {{ acceptanceResult.completeAppearance? '是':'否' }};{{ acceptanceResult.appearanceDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.appearanceDescriptionFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="立柱安装是否牢固：">
        <div>
          {{ acceptanceResult.columnInstallation? '是':'否' }};{{ acceptanceResult.columnInstallationDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.columnInstallationFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="插座安装是否牢固：">
        <div>
          {{ acceptanceResult.socketInstallation? '是':'否' }};{{ acceptanceResult.socketInstallationDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.socketInstallationFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="排管走线是否合适：">
        <div>
          {{ acceptanceResult.pipelineRouting? '是':'否' }};{{ acceptanceResult.pipelineRoutingDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.pipelineRoutingFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="存在通电问题的端口：">
        <div>
          {{ portDisplay }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="投币充电是否正常：">
        <div>
          {{ acceptanceResult.abnormalCoinCharging? '是':'否' }};{{ acceptanceResult.abnormalCoinChargingDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.abnormalCoinChargingFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="按键是否正常：">
        <div>
          {{ acceptanceResult.buttonNormal? '是':'否' }};{{ acceptanceResult.buttonNormalDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.buttonNormalFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="无其他故障现象：">
        <div>
          {{ acceptanceResult.otherProblem? '是':'否' }};{{ acceptanceResult.otherProblemDescription }}
        </div>
        <el-image v-for="file in acceptanceResult.otherProblemFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="经度">{{ acceptanceResult.longitude }}</el-descriptions-item>
      <el-descriptions-item label="纬度">{{ acceptanceResult.latitude }}</el-descriptions-item>
      <el-descriptions-item label="是否上线">{{ acceptanceResult.online? '是':'否' }}</el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {isResOK} from "@/api/response";
import {getInstallationTask} from "@/api/installation_task";
import {getEquipmentAcceptance, getInfoByInstallationTaskId} from "@/api/equipment_acceptance";

export default {
  name: "AcceptResult",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:visible'],
  data(){
    return {
      acceptanceResult: {},
      portDisplay: {},
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchResult();
  },
  methods: {
    fetchResult() {
      getInfoByInstallationTaskId({installationTaskId: this.id}).then(res => {
        if (isResOK(res)) {
          this.acceptanceResult = res.data
          this.acceptanceResult.portIds = JSON.parse(this.acceptanceResult.issuePorts)
          this.acceptanceResult.portIds.forEach(val => {
            this.portDisplay += `端口${val};`
          })
          this.handleImage()
        }
      })
    },
    handleImage() {
      this.acceptanceResult.appearanceDescriptionFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.columnInstallationFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.socketInstallationFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.pipelineRoutingFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.abnormalCoinChargingFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.buttonNormalFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.acceptanceResult.otherProblemFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
    },
    handleClose(){
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
