<template>
  <el-dialog
      v-model="isShow"
      title="设备安装结果"
      width="500px"
      :before-close="handleClose"
  >
    <el-descriptions column="1">
      <el-descriptions-item label="安装队伍："></el-descriptions-item>
      <el-descriptions-item label="提交时间">{{ installationTask.updateTime }}</el-descriptions-item>
      <el-divider />
      <el-descriptions-item label="设备编号：">{{ installationTask.deviceNo }}</el-descriptions-item>
      <el-descriptions-item label="线管密封照片：">
        <el-image v-for="file in installationTask.pipelineSealingFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="埋管走线照片：">
        <el-image v-for="file in installationTask.pipelineRoutingFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
      <el-descriptions-item label="整体安装位置照片：">
        <el-image v-for="file in installationTask.installationPositionFileList" style="width: 50px; height: 50px;margin:20px"
                  :preview-src-list="[file.url]"
                  :src="file.url" fit="contain"/>
      </el-descriptions-item>
    </el-descriptions>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {isResOK} from "@/api/response";
import {getInstallationTask} from "@/api/installation_task";

export default {
  name: "InstallationResult",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:visible'],
  data(){
    return {
      installationTask: {},
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchInstallationTask();
  },
  methods: {
    fetchInstallationTask() {
      getInstallationTask(this.id).then(res => {
        if (isResOK(res)) {
          this.installationTask = res.data
          this.handleImage()
        }
      })
    },
    handleImage() {
      this.installationTask.pipelineSealingFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.installationTask.pipelineRoutingFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
      this.installationTask.installationPositionFileList.forEach((file, index) => {
        file.url = process.env.VUE_APP_BASE_API + file.storageBlob.newFilename
      })
    },
    handleClose(){
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
