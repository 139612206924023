import request from '@/utils/request'

// 查询设备验收列表
export function listEquipmentAcceptance(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/equipment_acceptance/list',
        method: 'get',
        params: query
    })
}

// 查询设备验收详细
export function getEquipmentAcceptance(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/equipment_acceptance/' + id,
        method: 'get'
    })
}

// 查询设备验收详细通过任务id
export function getInfoByInstallationTaskId(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/equipment_acceptance/detail',
        method: 'get',
        params: query,
    })
}

// 新增设备验收
export function addEquipmentAcceptance(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/equipment_acceptance',
        method: 'post',
        data: data
    })
}

// 修改设备验收
export function updateEquipmentAcceptance(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/equipment_acceptance',
        method: 'put',
        data: data
    })
}

// 删除设备验收
export function delEquipmentAcceptance(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/business/equipment_acceptance/' + id,
        method: 'delete'
    })
}
