<template>
  <div>
    <el-main>
      <el-form
        :model="queryParams"
        ref="queryForm"
        :inline="true"
        label-width="85px"
      label-position="left"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item prop="name" label="计划名称">
              <el-input
                v-model="queryParams.name"
                placeholder="请输入计划名称"
                clearable
                @keyup.enter.native="handleQuery"
                style="width: 240px"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item prop="deviceName" label="设备名称">
              <el-input
                v-model="queryParams.deviceName"
                placeholder="请输入设备名称"
                clearable
                @keyup.enter.native="handleQuery"
                style="width: 240px"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item prop="deviceNo" label="设备编号">
              <el-input
                v-model="queryParams.deviceNo"
                placeholder="请输入设备编号"
                clearable
                @keyup.enter.native="handleQuery"
                style="width: 240px"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item prop="projectName" label="项目名称">
              <el-input
                v-model="queryParams.projectName"
                placeholder="请输入项目名称"
                clearable
                @keyup.enter.native="handleQuery"
                style="width: 240px"
              /> </el-form-item
          ></el-col>

          <el-col :span="6"
            ><el-form-item prop="projectTypeId" label="项目类型">
              <el-select
                v-model="queryParams.projectTypeId"
                filterable
                clearable
                placeholder="请选择项目类型"
                style="width: 240px"
              >
                <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item prop="status" label="选择状态">
              <el-select
                v-model="queryParams.status"
                filterable
                clearable
                placeholder="请选择状态"
                style="width: 240px"
              >
                <el-option
                  v-for="item in installationPlanStatusOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item prop="queryTime" label="创建时间">
              <el-date-picker
                v-model="queryParams.queryTime"
                type="date"
                placeholder="请选择创建时间"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                style="width: 240px"
              /> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item prop="areaId" label="所属区域">
              <el-cascader
                :key="cascaderKey"
                v-model="queryParams.areaId"
                :options="areaOptions"
                placeholder="请选择所属区域"
                :props="{
                  value: 'orgId',
                  label: 'orgName',
                  children: 'subOrgList',
                  checkStrictly: true,
                }"
                @change="handleChangeArea"
                style="width: 240px"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item >
              <el-button type="primary" @click="handleQuery">搜索</el-button>
              <el-button type="warning" @click="resetQuery">刷新</el-button>
            </el-form-item></el-col
          >
        </el-row>
      </el-form>

      <el-row
        :gutter="10"
        class="mb8"
        style="flex-flow: column; text-align: right"
      >
        <el-col :span="1.5">
          <el-button type="primary" plain @click="handleAdd">新增 </el-button>
        </el-col>
      </el-row>

      <el-table
        v-loading="loading"
        :data="installation_planList"
        @selection-change="handleSelectionChange"
      >
        <el-table-column width="100">
          <template #default="scope">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-image
                style="width: 49px; height: 49px"
                :src="backImgUrl"
                fit="contain"
              />
              <el-image
                style="width: 23px; height: 22px; position: absolute"
                :src="imgUrl"
                fit="contain"
              />
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
              "
            >
              <span
                style="
                  width: 60px;
                  text-align: center;
                  color: white;
                  border-radius: 2px;
                "
                :style="{
                  'background-color': statusBgColor[scope.row.statusCode],
                }"
              >
                {{ scope.row.statusName }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                <span style="font-weight: bold">{{ scope.row.name }}</span>
              </el-col>
              <el-col :span="12" style="text-align: right">
                <el-tooltip content="操作历史">
                  <el-icon
                    :size="20"
                    style="margin-right: 10px"
                    @click="showOperationHistoryModal(scope.row)"
                  >
                    <Clock />
                  </el-icon>
                </el-tooltip>
                <el-tooltip
                  content="编辑计划"
                  v-if="
                    'installing' !== scope.row.statusCode &&
                    'completed' !== scope.row.statusCode
                  "
                >
                  <el-icon
                    :size="20"
                    style="margin-right: 10px"
                    @click="handleUpdate(scope.row)"
                  >
                    <EditPen />
                  </el-icon>
                </el-tooltip>
                <el-tooltip
                  content="提交计划"
                  v-if="'draft' === scope.row.statusCode"
                >
                  <el-icon
                    :size="20"
                    style="margin-right: 10px"
                    @click="handleSubmit(scope.row)"
                  >
                    <CircleCheck />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="删除计划">
                  <el-icon
                    :size="20"
                    color="#ff0000"
                    @click="handleDelete(scope.row)"
                  >
                    <Delete />
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="5">
                <el-descriptions :column="1" :border="true">
                  <el-descriptions-item
                    label="项目名称："
                    span="1"
                    >{{ scope.row.project?.name }}</el-descriptions-item
                  >
                  <el-descriptions-item
                    label="项目类型："
                    span="1"
                    >{{ scope.row.projectType?.name }}</el-descriptions-item
                  >
                  <el-descriptions-item
                    label="创建时间："
                    span="1"
                    >{{ scope.row.createTime }}</el-descriptions-item
                  >
                </el-descriptions>
              </el-col>

              <el-col :span="5">
                <el-descriptions :column="1" :border="true">
                  <el-descriptions-item
                    label="所属区域："
                    span="1"
                    >{{ scope.row.project?.areaName }}</el-descriptions-item
                  >
                  <el-descriptions-item
                    label="详细地址："
                    span="1"
                    >{{ scope.row.project?.address }}</el-descriptions-item
                  >
                  <el-descriptions-item></el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="5">
                <el-descriptions :column="1"  :border="true">
                  <el-descriptions-item label="安装队伍：" >
                    {{ scope.row.teamName }}
                  </el-descriptions-item>
                  <el-descriptions-item label="安装时间：" >
                    <template #default>
                      <div style="white-space: pre-wrap;">
                        {{
                          scope.row.beginInstallTime === scope.row.endInstallTime
                              ? scope.row.beginInstallTime
                              : scope.row.beginInstallTime +
                              "~" + '\n' +
                              scope.row.endInstallTime
                        }}
                      </div>
                    </template>
                  </el-descriptions-item></el-descriptions
                >
                <el-descriptions-item></el-descriptions-item>
              </el-col>
              <el-col :span="5">
                <el-descriptions :column="1"  :border="true">
                  <el-descriptions-item label="设备数量：">
                    <el-button
                      key="primary"
                      type="primary"
                      link
                      @click="showInstallationTaskModal(scope.row)"
                      >{{ scope.row.deviceCount }}</el-button
                    >
                  </el-descriptions-item>
                  <el-descriptions-item label="物料总计：" >
                    4mm²线-{{ scope.row.wireBudget4 }}米；<br />1.5mm²线-{{
                      scope.row.wireBudget15
                    }}米
                  </el-descriptions-item>
                  <el-descriptions-item></el-descriptions-item>
                </el-descriptions>
              </el-col>

              <el-col :span="4" v-if="scope.row.statusCode === 'installing'">
                  <bar :id="scope.row.id" />
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        v-model:page="queryParams.pageNum"
        v-model:limit="queryParams.pageSize"
        @pagination="getList"
      />

      <el-dialog :title="title" v-model="open" width="800px">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="项目" prop="projectId">
            <el-select
              v-model="form.projectId"
              placeholder="请选择项目"
              style="width: 95%"
              @change="changeProject"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="计划名称" prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入计划名称"
              style="width: 95%"
            />
          </el-form-item>
          <el-form-item label="安装队伍" prop="teamId">
            <el-input
                ref="selectTeamInput"
                v-model="form.teamName"
                placeholder="请选择安装队伍"
                style="width: 95%"
                @focus="() => {
                  isShowSelectTeamModal = true
                  $refs.selectTeamInput.blur()
                }"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="安装时间" prop="installTime">
<!--            <el-date-picker-->
<!--              v-model="form.installTime"-->
<!--              type="daterange"-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始时间"-->
<!--              end-placeholder="结束时间"-->
<!--              value-format="YYYY-MM-DD"-->
<!--              @change="installTimeChange"-->
<!--            />-->
            <el-input
                ref="selectDateInput"
                v-model="form.installTime"
                placeholder="请选择安装时间"
                style="width: 95%"
                @focus="() => {
                  isShowSelectDateModal = true
                  $refs.selectDateInput.blur()
                }"
            />
          </el-form-item>
          <el-form-item label="安装说明" prop="description">
            <el-input
              type="textarea"
              v-model="form.description"
              placeholder="请输入"
              style="width: 95%"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item label="安装设备" prop="deviceIds">
            <el-select
              v-model="form.deviceIds"
              multiple
              placeholder="选择安装设备"
              style="width: 95%"
            >
              <el-option
                v-for="item in noInstallationPlanDevices"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="草稿" prop="draft">
            <el-checkbox v-model="form.draft" :checked="form.draft" />
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          style="flex-flow: column; text-align: right"
        >
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submitForm" :disabled="submitting">确 定</el-button>

        </div>
      </el-dialog>
      <operation-history-list-modal
        v-if="isShowOperationHistoryModal"
        :reference-id="showingOperationHistoryId"
        reference-type="InstallationPlan"
        v-model:visible="isShowOperationHistoryModal"
      />
      <!-- 安装计划 任务列表 -->
      <installation-task-index
        v-if="isShowInstallationTaskModal"
        :installation-plan-id="installationPlanId"
        v-model:visible="isShowInstallationTaskModal"
      />
      <select-team
        v-if="isShowSelectTeamModal"
        v-model:visible="isShowSelectTeamModal"
        :selected-team="selectedTeam"
        @completed="handleTeam"
      />
      <select-date
          v-if="isShowSelectDateModal"
          v-model:visible="isShowSelectDateModal"
          :team-id="form.teamId"
          :begin-install-time="form.beginInstallTime"
          :end-install-time="form.endInstallTime"
          @completed="handleDate"
      />
    </el-main>
  </div>
</template>

<script>
import {
  listInstallationPlan,
  getInstallationPlan,
  delInstallationPlan,
  addInstallationPlan,
  updateInstallationPlan,
  getInstallationPlanStatusOptions,
  submitInstallationPlan,
  checkName,
} from "@/api/installation_plan";
import { isResOK, isSysResOK } from "@/api/response";
import pagination from "@/components/Pagination/index.vue";
import { getProjectTypeOptions } from "@/api/project_type";
import { queryOrgRelTree } from "@/api/system";
import { areaOptions } from "@/api/mock";
import {
  getInstallationPlanProjectOptions,
  getSignedProjectOption,
  getNoInstallationPlanDevices,
} from "@/api/project";
import OperationHistoryListModal from "@/view/operation_history/Modal.vue";
import InstallationTaskIndex from "@/view/installation_task/index.vue";
import Bar from "@/view/installation_plan/bar.vue";
import {defineAsyncComponent} from "vue";
import dayjs from "dayjs";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";
export default {
  name: "InstallationPlan",
  components: {
    InstallationTaskIndex,
    OperationHistoryListModal,
    pagination,
    Bar,
    SelectTeam: defineAsyncComponent(() => import('@/view/installation_plan/SelectTeam.vue')),
    SelectDate: defineAsyncComponent(() => import('@/view/installation_plan/SelectDate.vue')),
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 安装计划表格数据
      installation_planList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        projectId: [
          { required: true, message: "项目不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "计划名称不能为空", trigger: "blur" },
          { min: 1, max: 50, message: "计划名称长度小于50", trigger: "blur" },
        ],
        teamId: [
          { required: true, message: "安装队伍不能为空" },
        ],
        deviceIds: [
          { required: true, message: "安装设备不能为空", trigger: "blur" },
        ],
        installTime: [
          { required: true, message: "安装时间不能为空" },
        ],
      },
      imgUrl: require(`/src/assets/plan.png`),
      backImgUrl: require(`/src/assets/plan_back.svg`),
      statusBgColor: {
        draft: "#AAAAAA",
        pending: "#f59a23",
        returned: "#ec808d",
        installing: "#409eff",
        completed: "#03b615",
      },
      productTypeOptions: [],
      installationPlanStatusOptions: [],
      areaOptions: [],
      projectOptions: [],
      noInstallationPlanDevices: [],
      projectId: 0,
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      installationPlanId: 0,
      isShowInstallationTaskModal: false,
      cascaderKey: 0,
      submitting: false,
      isShowSelectTeamModal: false,
      selectedTeam: {
        teamId: '',
        teamName: '',
      },
      key: 0,
      isShowSelectDateModal: false,
    };
  },
  created() {
    this.getList();
    this.getProductTypeOptions();
    this.queryOrgRelTree();
    this.getInstallationPlanStatusOptions();
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },
    showInstallationTaskModal(row) {
      this.installationPlanId = row.id;
      this.isShowInstallationTaskModal = true;
    },
    showOperationHistoryModal(row) {
      this.showingOperationHistoryId = row.id;
      this.isShowOperationHistoryModal = true;
    },
    changeProject(val) {
      this.form.deviceIds = null;
      this.projectId = val;
      this.form.name = undefined;
      this.projectOptions.forEach((item, index) => {
        if (item.id === val) {
          this.form.name = item.name;
        }
      });
      this.getNoInstallationPlanDevices();
    },
    getNoInstallationPlanDevices() {
      this.noInstallationPlanDevices = [];
      getNoInstallationPlanDevices(this.projectId, {
        installationPlanId: this.installationPlanId,
      }).then((res) => {
        if (isResOK(res)) {
          this.noInstallationPlanDevices = res.data;
        }
      });
    },
    getInstallationPlanProjectOptions(projectId) {
      this.projectOptions = [];
      getSignedProjectOption({ projectId: projectId }).then(
        (res) => {
          if (isResOK(res)) {
            this.projectOptions = res.data;
          }
        }
      );
    },
    installTimeChange(val) {
      if (val !== null && val.length == 2) {
        this.form.beginInstallTime = val[0];
        this.form.endInstallTime = val[1];
      }
    },
    handleChangeArea(val) {
      if (val !== null && val !== undefined) {
        this.queryParams.areaId = val.join(",");
      } else {
        this.queryParams.areaId = undefined;
      }
    },
    queryOrgRelTree() {
      queryOrgRelTree({
        orgId: "1681542405985234945",
        relType: "1",
      }).then((res) => {
        if (isSysResOK(res)) {
          this.areaOptions.push(res.result.orgRelInfoVo);
        }
      });
    },
    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },
    getInstallationPlanStatusOptions() {
      getInstallationPlanStatusOptions().then((res) => {
        if (isResOK(res)) {
          this.installationPlanStatusOptions = res.data;
        }
      });
    },
    /** 查询安装计划列表 */
    getList() {
      this.loading = true;
      listInstallationPlan(this.queryParams).then((response) => {
        if (isResOK(response)) {
          this.installation_planList = response.rows;
          this.total = response.total;
        }
        this.loading = false;
        this.submitting = false
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        projectId: null,
        name: null,
        teamId: null,
        teamName: null,
        beginInstallTime: null,
        endInstallTime: null,
        deviceCount: null,
        draft: null,
        status: null,
        createTime: null,
        updateTime: null,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.cascaderKey = new Date().getTime();
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.installationPlanId = 0;
      this.getInstallationPlanProjectOptions(0);
      this.reset();
      this.form.draft = true;
      this.open = true;
      this.title = "新增安装计划";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.installationPlanId = row.id;
      this.projectId = row.projectId;
      this.getInstallationPlanProjectOptions(row.projectId);
      this.getNoInstallationPlanDevices();
      this.reset();
      const id = row.id || this.ids;
      getInstallationPlan(id).then((response) => {
        if (isResOK(response)) {
          this.form = response.data;
          this.form.draft = this.form.statusCode === "draft";
          this.form.installTime = [
            this.form.beginInstallTime,
            this.form.endInstallTime,
          ];
          this.handleDate({
            beginInstallTime: this.form.beginInstallTime,
            endInstallTime: this.form.endInstallTime,
          })
        }
        this.open = true;
        this.title = "编辑安装计划";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.submitting = true
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            checkName(this.form).then((res) =>{
              if (isResOK(res)){
                if (res.data.uniq === false){
                  this.$messageBox.confirm(
                      '计划名称已存在，确定创建吗？',
                      {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                      }
                  ).then(() => {
                    this.updateData()
                  }).catch(() => {
                    this.submitting = false
                  })
                }else{
                  this.updateData()
                }
              } else {
                this.submitting = false
              }
            })
          } else {
            checkName(this.form).then((res) =>{
              if (isResOK(res)){
                if (res.data.uniq === false){
                  this.$messageBox.confirm(
                      '计划名称已存在，确定创建吗？',
                      {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                      }
                  ).then(() => {
                    this.insertInstallationPlan()
                  }).catch(() => {
                    this.submitting = false
                  })
                }else{
                  this.insertInstallationPlan()
                }
              } else {
                this.submitting = false
              }
            })
          }
        } else {
          this.submitting = false
        }
      });
    },
    insertInstallationPlan() {
      let operatorId = ''
      let operatorName = ''
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo !== undefined){
        operatorId = userInfo.id
        operatorName = userInfo.name
      }
      addInstallationPlan(Object.assign({}, this.form, { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName })).then((response) => {
        if (isResOK(response)) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.open = false;
          this.getList();
          this.submitting = false
        }
      });
    },
    updateData() {
      let operatorId = ''
      let operatorName = ''
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo !== undefined){
        operatorId = userInfo.id
        operatorName = userInfo.name
      }
      updateInstallationPlan(Object.assign({}, this.form, { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName })).then((response) => {
        if (isResOK(response)) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.open = false;
          this.getList();
          this.submitting = false
        }
      });
    },
    handleSubmit(row) {
      const id = row.id;
      this.$messageBox
        .confirm("确定提交计划吗？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let operatorId = ''
          let operatorName = ''
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (userInfo !== undefined){
            operatorId = userInfo.id
            operatorName = userInfo.name
          }
          submitInstallationPlan(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
            if (isResOK(res)) {
              this.$message({ message: "提交成功", type: "success" });
            }
            this.getList();
          });
        })
        .catch(() => {});
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      let msg = "";
      if (
        row.statusCode === "draft" ||
        row.statusCode === "pending" ||
        row.statusCode === "returned"
      ) {
        msg = "确定删除计划吗？";
      } else {
        msg = "确定删除吗？删除后关联的安装任务也会同步删除";
      }
      this.$messageBox
        .confirm(msg, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          delInstallationPlan(ids, { operatingMode: 'web' }).then((res) => {
            if (isResOK(res)) {
              this.$message({ message: "删除成功", type: "success" });
            }
            this.getList();
          });
        })
        .catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "business/installation_plan/export",
        {
          ...this.queryParams,
        },
        `installation_plan_${new Date().getTime()}.xlsx`
      );
    },
    handleTeam(team){
      this.selectedTeam = team
      this.form.teamId = team.teamId
      this.form.teamName = team.teamName
    },
    handleDate(form){
      this.form.beginInstallTime =  form.beginInstallTime
      this.form.endInstallTime = form.endInstallTime
      if (this.form.beginInstallTime instanceof Date && this.form.endInstallTime instanceof Date) {
        this.form.installTime = `${dayjs(this.form.beginInstallTime).format('YYYY-MM-DD')}至${dayjs(this.form.endInstallTime).format('YYYY-MM-DD')}`
      } else {
        this.form.installTime = `${this.form.beginInstallTime}至${this.form.endInstallTime}`
      }
    }
  },
};
</script>
<style scoped lang="less">
 ::v-deep .el-descriptions__label {
   background-color: #FFFFFF!important;
   width: 92px;
   border: 0px!important;
   vertical-align: top;
    font-weight:0 !important

 }
 ::v-deep .el-descriptions__content {
   border: 0px!important;
 }
 ::v-deep .el-col-5 {
   background-color: var(--el-fill-color-blank);
 }
</style>
