<template>
  <div>
    <div ref="taskStatusPie" style="height: 130px; width: 100%"></div>
  </div>
</template>

<script>
import { getStatusStatistics } from "@/api/installation_task";
import { isResOK } from "@/api/response";
export default {
  name: "",
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statusStatistics: [],
    };
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        title: {
          show:true,//false
          text:"安装任务状态分布",//主标题文本
          left: 0 ,
          top: 14,
          padding: [0, 0, 0, 0],
          textStyle: {
            fontSize: 12,//字体大小
          }
          
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "10%",
          top: "center",
          orient: "vertical",
          itemHeight: 8,
          itemWidth: 8,
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "50%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 10,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      };
    },
  },
  mounted() {
    this.fetchTaskStatusStatistics();
  },
  methods: {
    fetchTaskStatusStatistics() {
      getStatusStatistics({installationPlanId: this.id}).then((res) => {
        if (isResOK(res)) {
          this.statusStatistics = res.data;
          this.drawChart();
        }
      });
    },
    drawChart() {
      this.chartOption.series[0].data = [];
      this.statusStatistics.forEach((item) => {
        this.chartOption.series[0].data.push({
          name: item.statusName + `:  ${item.count}`,
          value: item.count,
        });
      });
      this.chart ||= this.$echarts.init(this.$refs.taskStatusPie);
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true);
      // 添加表格自适应
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
  },
};
</script>

<style scoped></style>
